import React, { useEffect, useState, useRef, useMemo } from "react";
import { Box, Typography, keyframes } from "@mui/material";
import { shades } from "../theme";

const DynamicBanner = () => {
  const [bannerData, setBannerData] = useState({});
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messageRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    fetch("https://liiix-store-wvm88.ondigitalocean.app/api/dynamic-contents")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.length > 0) {
          setBannerData(data.data[0].attributes);
        }
      })
      .catch((error) => console.error("Error fetching banner data:", error));
  }, []);

  const messages = useMemo(() => {
    if (
      !bannerData.firstBannerMessage &&
      !bannerData.secondBannerMessage &&
      !bannerData.thirdBannerMessage
    ) {
      return [];
    }
    return [
      { text: bannerData.firstBannerMessage, color: "#00FFFF" },
      { text: bannerData.secondBannerMessage, color: "#E6E6FA" },
      { text: bannerData.thirdBannerMessage, color: "#FFDAB9" },
    ];
  }, [bannerData]);

  useEffect(() => {
    const handleAnimationIteration = () => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    };

    const messageElement = messageRef.current;
    if (messageElement) {
      messageElement.addEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    }

    return () => {
      if (messageElement) {
        messageElement.removeEventListener(
          "animationiteration",
          handleAnimationIteration
        );
      }
    };
  }, [messages]);

  useEffect(() => {
    const textWidth = messageRef.current ? messageRef.current.offsetWidth : 0;
    const containerWidth = containerRef.current
      ? containerRef.current.offsetWidth
      : 0;
    const duration = textWidth > containerWidth ? (textWidth / 300) * 10 : 10;
    if (messageRef.current) {
      messageRef.current.style.animationDuration = `${duration}s`;
    }
  }, [currentMessageIndex, messages]);

  if (messages.length === 0) {
    return null;
  }

  const currentMessage = messages[currentMessageIndex];

  const movingText = keyframes`
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  `;

  return (
    <Box
      sx={{
        backgroundColor: shades.neutral2[900],
        overflow: "hidden",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "5vh",
        zIndex: 100,
        whiteSpace: "nowrap",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Typography
        ref={messageRef}
        variant="h4"
        sx={{
          position: "absolute",
          animation: `${movingText} linear infinite`,
          width: "100%",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "10ox",
          color: currentMessage.color,
        }}
      >
        {currentMessage.text}
      </Typography>
    </Box>
  );
};

export default DynamicBanner;
