import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserToken } from "../scenes/tracking/GetUserId";

// Async thunk for adding an item to the wishlist in the backend
export const addItemToWishlist = createAsyncThunk(
  "wishlist/addItemToWishlist",
  async (item, { rejectWithValue }) => {
    const token = getUserToken();

    if (token) {
      console.log("🚀 ~ token:", token);
      try {
        const requestBody = { data: { item: item.id } };
        const response = await fetch(
          `https://liiix-store-wvm88.ondigitalocean.app/api/wishlist-items`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) throw new Error("Could not add item to wishlist");
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      // Logic for unauthenticated users using localStorage
      try {
        let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        wishlist.push(item);
        localStorage.setItem("wishlist", JSON.stringify(wishlist));
        return item; // Mimic the expected format if necessary
      } catch (error) {
        return rejectWithValue("Could not add item to local wishlist");
      }
    }
  }
);

// Async thunk for fetching wishlist items
export const fetchWishlistItems = createAsyncThunk(
  "wishlist/fetchWishlistItems",
  async (_, { rejectWithValue }) => {
    const token = getUserToken();

    if (token) {
      console.log("🚀 ~ token:", token);
      // Original logic for authenticated users
      try {
        const response = await fetch(
          `https://liiix-store-wvm88.ondigitalocean.app/api/wishlist-items`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error("Could not fetch wishlist items");
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      // Logic for unauthenticated users using localStorage
      try {
        let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        return { data: wishlist }; // Ensure this matches your data structure
      } catch (error) {
        return rejectWithValue("Could not fetch local wishlist");
      }
    }
  }
);

// Async thunk for deleting a wishlist item
export const deleteWishlistItem = createAsyncThunk(
  "wishlist/deleteWishlistItem",
  async (wishlistItemId, { rejectWithValue }) => {
    const token = getUserToken();

    if (token) {
      // Original logic for authenticated users
      try {
        const response = await fetch(
          `https://liiix-store-wvm88.ondigitalocean.app/api/wishlist-items/${wishlistItemId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error("Could not delete wishlist item");
        return wishlistItemId;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      // Logic for unauthenticated users using localStorage
      try {
        let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        wishlist = wishlist.filter((item) => item.id !== wishlistItemId);
        localStorage.setItem("wishlist", JSON.stringify(wishlist));
        return wishlistItemId;
      } catch (error) {
        return rejectWithValue("Could not delete local wishlist item");
      }
    }
  }
);

// Initial state for the wishlist
const initialState = {
  items: [],
  status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    // Reducers for other synchronous actions related to wishlist
  },
  extraReducers: (builder) => {
    builder
      .addCase(addItemToWishlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addItemToWishlist.fulfilled, (state, action) => {
        state.items.push(action.payload); // Add the new wishlist item to the state
        state.status = "succeeded";
      })
      .addCase(addItemToWishlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchWishlistItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWishlistItems.fulfilled, (state, action) => {
        state.items = action.payload.data; // Assuming the payload is an array of items
        state.status = "succeeded";
      })
      .addCase(fetchWishlistItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteWishlistItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteWishlistItem.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload); // Remove the item by id
        state.status = "succeeded";
      })
      .addCase(deleteWishlistItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default wishlistSlice.reducer;
