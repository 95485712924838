import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import DynamicBanner from "./components/HeaderBanner";
const ItemDetails = lazy(() => import("./scenes/itemDetails/ItemDetails"));
const Checkout = lazy(() => import("./scenes/checkout/Checkout"));
const Confirmation = lazy(() => import("./scenes/checkout/Confirmation"));
const Home = lazy(() => import("./scenes/home/Home"));
const Navbar = lazy(() => import("./scenes/global/Navbar"));
const CartMenu = lazy(() => import("./scenes/global/CartMenu"));
const Footer = lazy(() => import("./scenes/global/Footer"));
const LoginPage = lazy(() => import("./scenes/userPage/LoginPage"));
const RegisterPage = lazy(() => import("./scenes/userPage/RegisterPage"));
const UserPage = lazy(() => import("./scenes/userPage/userPage"));
const Category = lazy(() => import("./scenes/global/Category"));
const CategoryPage = lazy(() => import("./scenes/category/CategoryPage"));
const TermsAndConditions = lazy(() =>
  import("./scenes/terms/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("./scenes/terms/PrivacyPolicy"));
const ForgotPasswordPage = lazy(() =>
  import("./scenes/userPage/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("./scenes/userPage/ResetPasswordPage")
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <DynamicBanner />
        <Navbar />

        <ScrollToTop />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/category/:category" element={<CategoryPage />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout/success" element={<Confirmation />} />
            <Route path="/terms&conditions" element={<TermsAndConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Routes>
        </Suspense>
        <CartMenu />
        <Category />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
