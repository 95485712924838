import { createTheme } from "@mui/material/styles";

export const shades = {
  primary: {
    100: "#cccccc",
    200: "#999999",
    300: "#666666",
    400: "#333333",
    500: "#000000",
    600: "#000000",
    700: "#000000",
    800: "#000000",
    900: "#000000",
  },

  neutral2: {
    100: "#e7f5fb",
    200: "#cfebf7",
    300: "#b7e2f3",
    400: "#9fd8ef",
    500: "#87ceeb",
    600: "#6ca5bc",
    700: "#517c8d",
    800: "#36525e",
    900: "#1b292f",
  },

  secondary: {
    100: "#e9e2d7",
    200: "#d3c4ae",
    300: "#bca786",
    400: "#a6895d",
    500: "#906c35",
    600: "#73562a",
    700: "#564120",
    800: "#3a2b15",
    900: "#1d160b",
  },

  neutral: {
    100: "#f5f5f5",
    200: "#ecebeb",
    300: "#e2e1e1",
    400: "#d9d7d7",
    500: "#cfcdcd",
    600: "#a6a4a4",
    700: "#7c7b7b",
    800: "#535252",
    900: "#292929",
  },
  black: {
    main: "#000000",
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      light: shades.primary[200],
      main: shades.primary[500],
    },
    secondary: {
      main: shades.secondary[500],
    },
    neutral: {
      dark: shades.neutral[700],
      main: shades.neutral[500],
      light: shades.neutral[100],
    },
    neutral2: {
      dark: shades.neutral2[700],
      main: shades.neutral2[500],
      light: shades.neutral2[100],
    },
  },
  typography: {
    fontFamily: ["Fauna One", "sans-serif"].join(","),
    fontSize: 11,
    h1: {
      fontFamily: ["Cinzel", "sans-serif"].join(","),
      fontSize: 48,
    },
    h2: {
      fontFamily: ["Cinzel", "sans-serif"].join(","),
      fontSize: 36,
    },
    h3: {
      fontFamily: ["Cinzel", "sans-serif"].join(","),
      fontSize: 20,
    },
    h4: {
      fontFamily: ["Cinzel", "sans-serif"].join(","),
      fontSize: 14,
    },
    h5: {
      fontFamily: ["Tangerine ", "sans-serif"].join(","),
    },
    h6: {
      fontFamily: ["Cinzel Decorative", "serif"].join(","),
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 911,
      lg: 1280,
      xl: 1920,
    },
  },
});
