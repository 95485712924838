// exchangeRatesSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Async thunk to fetch exchange rates from Strapi backend
export const fetchExchangeRates = createAsyncThunk(
  "exchangeRates/fetchExchangeRates",
  async (countryCode = "USD", { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://liiix-store-wvm88.ondigitalocean.app/api/currency?base=${countryCode}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  rates: {},
  selectedCountry: "USD",
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState,
  reducers: {
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRates.pending, (state) => {
        state.status = "loading";
      })

      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rates = action.payload;
      })
      .addCase(fetchExchangeRates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { setSelectedCountry } = exchangeRatesSlice.actions;
export default exchangeRatesSlice.reducer;
