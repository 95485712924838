// GetUserId.js

export const getUserId = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const tokenPayload = JSON.parse(atob(token.split(".")[1]));
      return tokenPayload.id; // Adjust to 'id' or the correct field name
    } catch (error) {
      console.error("Error parsing JWT token:", error);
    }
  }
  return null;
};

// Function to retrieve the user token from localStorage
export const getUserToken = () => {
  return localStorage.getItem("token");
};

export const isLoggedIn = () => {
  const token = getUserToken();
  return !!token;
};
