import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to fetch categories
export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://liiix-store-wvm88.ondigitalocean.app/api/items?populate=image`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const itemsData = await response.json();

      // Extract categories from the response
      const categories = new Set();
      itemsData.data.forEach((item) => {
        categories.add(item.attributes.category);
      });
      return Array.from(categories);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  categories: [],
  isCategoryOpen: false,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setIsCategoryOpen: (state, action) => {
      state.isCategoryOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        // Handle rejected state or error logging
        console.error("Failed to fetch categories:", action.payload);
      });
  },
});

export const { setIsCategoryOpen } = categorySlice.actions;

export default categorySlice.reducer;
